// extracted by mini-css-extract-plugin
export var booksGrid = "Biblioteca-module--booksGrid--1b5c4";
export var container = "Biblioteca-module--container--9f592";
export var contentContainer = "Biblioteca-module--contentContainer--d5f5d";
export var gatsbyImageWrapper = "Biblioteca-module--gatsby-image-wrapper--ca60e";
export var image = "Biblioteca-module--image--b2c8a";
export var smallText = "Biblioteca-module--smallText--f1006";
export var text = "Biblioteca-module--text--3f011";
export var title = "Biblioteca-module--title--bd6c5";
export var typing = "Biblioteca-module--typing--5b185";
export var typingDots = "Biblioteca-module--typingDots--8c38c";